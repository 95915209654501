import firebase from "firebase/compat/app";
import "firebase/compat/storage";
export async function uploadPdfToFirebase(file) {
  console.log(file);
  const storageRef = firebase.storage().ref();

  const pdfName = `${Date.now()}_${file.name}`;

  const pdfRef = storageRef.child(`pdfs/${pdfName}`);

  try {
    await pdfRef.put(file);

    const downloadURL = await pdfRef.getDownloadURL();
    console.log("downloadURL", downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading PDF:", error);
    throw error;
  }
}
export async function uploadImageSuccess(formData, index, fileList) {
  const uploadedImageUrls = [];
  const imageName = fileList[index].name;
  const ext = imageName.slice(imageName.lastIndexOf("."));
  const file = convertImagePathToFile(fileList[index]);
  const storageRef = firebase.storage().ref(`web_pros/${imageName}`);
  try {
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();
    uploadedImageUrls.push({ src: url });
  } catch (error) {
    console.error("Error uploading image:", error);
    // Handle any errors
    throw error; // Optionally, you can re-throw the error if needed
  }

  return uploadedImageUrls;
}

function convertImagePathToFile(element) {
  const byteString = atob(element.path.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ia], { type: "image/jpeg" });

  return new File([blob], element.name);
}
