<template>
  <div>
    <b-overlay
      :show="imageOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <vue-upload-multiple-image
        v-model="images"
        style="margin-top: 2px"
        :data-images="images"
        name="images"
        drag-text="Veuillez ajoutez une images svp!"
        browse-text="Browse image"
        primary-text="Primary image"
        mark-is-primary-text="Set default image"
        :min-image="1"
        :max-image="1"
        :showEdit="false"
        popup-text="Description default image"
        drop-text="Drag and drop"
        :idUpload="item.index.toString()"
        @upload-success="uploadImageAndReturnSrc"
        @before-remove="beforeRemove"
      >
      </vue-upload-multiple-image>
    </b-overlay>
  </div>
</template>
<script>
import { BOverlay } from "bootstrap-vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { uploadImageSuccess } from "./ProductSettings/FileUpload"; // Import the function
export default {
  components: {
    BOverlay,
    VueUploadMultipleImage,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      imageOverlay: false,
      images: [],
    };
  },
  methods: {
    beforeRemove(index, done, fileList) {
      var r = confirm("remove image");
      if (r == true) {
        var indexInArrayImages = this.images.indexOf(fileList[index].path);
        if (index > -1) {
          this.images.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    async uploadImageAndReturnSrc(formData, index, fileList) {
      this.imageOverlay = true;
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );

        this.item.image = uploadedUrls[0];
        this.imageOverlay = false;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    transformImagesUploadToImagesArray(imagesUploadArray) {
      const transformedArray = [];

      imagesUploadArray.forEach((item) => {
        transformedArray.push({
          name: "",
          path: item.src,
          highlight: 1,
          default: 1,
        });
      });

      return transformedArray;
    },
  },
};
</script>
