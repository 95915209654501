<
<template>
  <div class="app-ecommerce">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3"
      >
        <div class="d-flex flex-column justify-content-center">
          <h4 class="mb-1 mt-3"></h4>
        </div>
        <div class="d-flex align-content-center flex-wrap gap-3">
          <div class="d-flex gap-3">
            <button
              type="submit"
              class="btn btn-primary"
              @click="showMsgBoxTwo"
            >
              Publish product
            </button>
          </div>

          <button type="submit" class="btn btn-secondary ml-3">Discard</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-7">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-tile mb-0">Product information</h5>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="ecommerce-product-name"
                  >Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="product.name"
                  placeholder="Product title"
                  name="productTitle"
                  aria-label="Product title"
                />
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label class="form-label" for="ecommerce-product-sku"
                    >SKU</label
                  >
                  <input
                    class="form-control"
                    v-model="product.sku"
                    placeholder="SKU"
                    name="productSku"
                    aria-label="Product SKU"
                  />
                </div>
              </div>
              <div>
                <H5 class="form-label">Description </H5>
                <quill-editor v-model="product.description" />
              </div>
              <div class="mt-2">
                <H5 class="form-label"> Short Description </H5>
                <quill-editor v-model="product.short_description" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Categories</h5>
              <feather-icon
                style="color: #19b4bf; cursor: pointer"
                icon="RefreshCcwIcon"
                size="20"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                title="Refresh category list"
                variant="gradient-primary"
                @click="refrechCategory()"
              />
            </div>
            <div class="card-body">
              <div class="mb-3">
                <v-select
                  v-model="product.categories"
                  multiple
                  :loading="loadingCategories"
                  :options="flattenedCategories"
                  label="formattedName"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Brands</h5>
              <feather-icon
                style="color: #19b4bf; cursor: pointer"
                icon="RefreshCcwIcon"
                size="20"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                title="Refresh category list"
                variant="gradient-primary"
                @click="refrechBands()"
              />
            </div>
            <div class="card-body">
              <div class="mb-3">
                <v-select
                  v-model="product.brands"
                  :options="brands"
                  label="name"
                  :loading="loadingBrands"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card mb-4">
            <div class="card-header">
              <h5 class="card-title mb-0">Media</h5>
            </div>
            <div class="card-body">
              <div class="row mb-3">
                <b-overlay
                  :show="imageOverlay"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                  rounded="sm"
                >
                  <vue-upload-multiple-image
                    v-model="imagesUrl"
                    :data-images="imagesUrl"
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageAndReturnSrc"
                  >
                  </vue-upload-multiple-image>
                </b-overlay>
                <div class="col">
                  <div class="mb-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProductVariantForm :variant="variant" :attribute="attribute" />
      </div>
    </b-overlay>
  </div>
</template>
<script>
import ProductVariantForm from "./ProductVariantForm .vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import axios from "axios";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import websiteURL from "@/websiteURL";
import {
  BFormTextarea,
  BFormGroup,
  BRow,
  BForm,
  BFormInput,
  BCol,
  BButton,
  BCard,
  BTabs,
  BTab,
  BCardText,
  BPopover,
  BCardBody,
  BOverlay,
  BFormSelect,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";
import ProductSettingPrice from "./ProductSettings/ProductSettingPrice.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";
import { useCurrencyInput } from "vue-currency-input";
import { uploadImageSuccess } from "./ProductSettings/FileUpload"; // Import the function
import {
  getAttributeTerms,
  AddProduct,
  getParentCategories,
  getBrands,
} from "../../services/products/Products"; // Import the Service

export default {
  components: {
    ProductVariantForm,
    BFormTextarea,
    BCol,
    BButton,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BCol,
    BButton,
    BCard,
    VBTooltip,
    BFormSelect,
    vSelect,
    useCurrencyInput,
    Cleave,
    BPopover,
    BOverlay,
    BCardBody,
    BCardText,
    BTabs,
    BTab,
    ProductSettingPrice,
    quillEditor,
    VueUploadMultipleImage,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      itemFormBlankItem: {
        item: null,
        cost: 0,
        qty: 0,
        description: "",
      },
      selectedAttribute: null,
      attribute: [],
      variant: [],
      nextTodoId: 2,
      planPromo: false,
      locale: "fr",
      data: {
        selectedDate: new Date(),
      },
      selected: { title: "Square" },
      option: [
        { title: "Square" },
        { title: "Rectangle" },
        { title: "Rombo" },
        { title: "Romboid" },
      ],
      selectedCategory: [],
      imageOverlay: false,
      product: {
        images: [],
        categories: [],
        brands: [],
      },
      content: "",
      options: {},
      boxOne: "",
      boxTwo: "",
      loading: false,
      permalink: "",
      images: [],
      imagesUrl: [],
      regular_price: "",
      sale_price: "",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: false,
      valueWhenIsEmpty: "",
      options: {
        locale: "fr-TN",
        prefix: "DT",
        suffix: "",
        length: 11,
        max: 50000,
        precision: 3,
      },
      price: "",
      categories: [],
      brands: [],
      selectedCategories: [],
      categoriesLoader: false,
      BandsLoader: false,
      termData: [],
      loadingBrands: false,
      loadingCategories: false,
    };
  },
  computed: {
    flattenedCategories() {
      const flattened = [];
      for (const category of this.categories) {
        flattened.push({
          ...category,
          formattedName: `* ${category.name}`,
          disabled: true,
        });

        if (category.subcategories && category.subcategories.length > 0) {
          for (const subcategory of category.subcategories) {
            flattened.push({
              ...subcategory,
              formattedName: `     \u00A0 - ${subcategory.name}`,
              disabled: false,
            });
          }
        }
      }
      return flattened;
    },
  },
  methods: {
    async uploadImageAndReturnSrc(formData, index, fileList) {
      this.imageOverlay = true;
      try {
        const uploadedUrls = await uploadImageSuccess(
          formData,
          index,
          fileList
        );
        this.product.images.push(...uploadedUrls);
        this.imageOverlay = false;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
    showMsgBoxTwo() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to add Product.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.ajoutProduit().then(() => {
                this.makeToast("info");
                setTimeout(() => {
                  this.$router.push({
                    name: "product",
                  });
                }, 5000);
              });
            }
          }
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("product added successfully ", {
        title: `Product ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    async ajoutProduit() {
      if (this.sale_price) {
        this.product.sale_price = this.sale_price.toString();
      }
      if (this.regular_price) {
        this.product.regular_price = this.regular_price.toString();
      }

      this.variant = this.variant
        .filter((item) => item.selectedAttribute && item.terms)
        .map((item) => {
          const { selectedAttribute, terms } = item;
          return {
            ...item,
            attributes: [
              {
                id: selectedAttribute.id,
                option: terms.name,
              },
            ],
          };
        });
      const productAttributes = [];
      this.variant.forEach((item) => {
        const { selectedAttribute, terms } = item;

        const existingAttribute = productAttributes.find(
          (attr) => attr.id === selectedAttribute.id
        );

        if (!existingAttribute) {
          const newAttribute = {
            id: selectedAttribute.id,
            position: 0,
            visible: true,
            variation: true,
            options: [terms.name],
          };

          productAttributes.push(newAttribute);
        } else {
          existingAttribute.options.push(terms.name);
        }
      });

      this.product.attributes = productAttributes;
      this.product.variations = this.variant;
      this.product.type = "variable";

      try {
        const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products";
        const consumerKey = websiteURL.CONSUMERKEY;
        const consumerSecret = websiteURL.CONSUMERSECRET;
        const productResponse = await axios.post(apiUrl, this.product, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });

        const productId = productResponse.data.id;
        const variationResponses = [];

        for (const variation of this.variant) {
          const variationApiUrl = `${websiteURL.WEBSITE}wp-json/wc/v3/products/${productId}/variations`;
          const variationResponse = await axios.post(
            variationApiUrl,
            variation,
            {
              auth: {
                username: consumerKey,
                password: consumerSecret,
              },
            }
          );
          variationResponses.push(variationResponse.data);
          this.permalink = productResponse.data.permalink;
        }
      } catch (error) {
        console.error("Error adding product and variations:", error);
        throw error;
      }
    },

    formatDinar() {
      if (this.price != "") {
        var num = this.price;
        num = Number(num);
        var countDecimals = function (value) {
          if (Math.floor(value) === value) return 0;
          return value.toString().split(".")[1].length || 0;
        };

        var decimal = countDecimals(num);

        if (decimal < 2) {
          num = num.toFixed(2);
        }

        if (decimal > 2) {
          num = num.toFixed(decimal);
        }

        if (parseInt(num) < 1) {
          num = "." + String(num).split(".")[1];
        }
        this.price = num;
      }
    },
    async refrechCategory() {
      this.loadingCategories = true;
      this.categories = await getParentCategories();
      this.loadingCategories = false;
    },
    async refrechBands() {
      this.loadingBrands = true;
      this.brands = await getBrands();
      this.loadingBrands = false;
    },
  },
  async created() {
    this.loadingCategories = true;
    this.loadingBrands = true;
    this.attribute = await getAttributeTerms();
    this.categories = await getParentCategories();
    this.brands = await getBrands();
    this.loadingBrands = false;
    this.loadingCategories = false;
  },
};
</script>
<style lang="scss">
// Style
.ql-snow {
  &.ql-container {
    .ql-editor {
      min-height: 200px;
    }
  }
}
.currency-input {
  position: relative;

  input {
    padding-left: 3rem;
    width: 100%;
  }

  .currency-symbol {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    text-align: center;
    transform: translateY(-50%);
  }

  input:invalid ~ .currency-symbol {
    opacity: 0.5;
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
